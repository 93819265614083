$(function(){
  var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
  var before_delta_minus = 0
  var before_delta_plus = 0
  $(document).on(mousewheelevent,function(e){
    if($(".main").data("story")===0){
      var num = $(".main").data("sv");
      var delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);
      if (delta < 0){
        if(delta < before_delta_minus){
          var dNum = num - 1;
          before_delta_minus = delta
          $(".main").data("sv",dNum);          
        }
      } else {
        if(delta > before_delta_plus){
          var uNum = num + 1;
          before_delta_plus = delta
          $(".main").data("sv",uNum);
        }
      }
      var next_section_num = $(".main").data("vp") + 1;
      var before_section_num = $(".main").data("vp") - 1;
      if(next_section_num > 4){
        next_section_num = 4
      };
      if(before_section_num < 1){
        before_section_num = 1
      };
      if($(".main").data("sv") === 8){
        $(".main").data("sv",0);
        $('.loading').css("display","block");
        $.ajax({
          url: '/tops/section' + before_section_num,
        });
      }else if($(".main").data("sv") === -8){
        $(".main").data("sv",0);
        $('.loading').css("display","block");
        $.ajax({
          url: '/tops/section' + next_section_num,
        });
      };    
    };
  });
  setInterval(function(){
    before_delta_minus = 0
    before_delta_plus = 0
  },600)
});