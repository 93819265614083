$(function(){

  $(window).on("load", function () {
    $('.loading').fadeOut(500);
  });


  var fadeLoading = function(){
    $('.loading').fadeOut(500);
  };
  var movePageAnimation = function(target){
    $('.loading').show();
    setTimeout(fadeLoading, 500);
    $('.select-btn').removeClass('selected');
    $(target).addClass('selected');
  };

  $('#next').on('click', function (e) {
    var next_view_num = $('.main').data("np");
    $(this).attr("href","tops/section" + next_view_num);
    if(next_view_num === 4){
      $(".main").data("np",1);
      $(this).find("p").text("TOP");
    }else{
      $(".main").data("np",next_view_num + 1);
      $(this).find("p").text("NEXT");
    };
  });
  $('.menu-text').on('click', function (e) {
    var npp = $(this).data("npp");
    $(".main").data("np",npp)
    if(npp === 1){
      $("#next").find("p").text("TOP")
    }else{
      $("#next").find("p").text("NEXT")
    };
  });
});