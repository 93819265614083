$(function(){
  $('.fadein').each(function(){
      var position = $(this).offset().top;
      var scroll = $('body').scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 50){
        $(this).addClass('active');
      }
  });
  $(".fadein-pc").each(function(){
    $(this).addClass('active')
  });
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0) {
    $(".fadein-tb").each(function(){
      $(this).addClass('active')
    });
  }
});